@import url(https://fonts.googleapis.com/css?family=Rubik);

body {
    font-family: 'Rubik', sans-serif;
    margin: auto;
    overflow-x: hidden;
}

#presentation {
    background: url("/wp-content/themes/christianvanasse/assets/images/backgrounds/japon.jpg");
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center; 
    margin-bottom: -130px;
    padding-bottom: 200px;
}

.facebook {
    max-width: 150px;
    margin: 21px;
}

.facebook:hover {
    filter: invert(100%);
}

#masque {
  max-width: 350px;
  width: 100%;
  padding-top: 22px;  
}

.img-margin {
    display: block;
    height: auto;
    max-width: 100%;
      margin:0 auto;
}

#description {
    background: #0d0d0d;
    padding-top: 31px;
    padding-bottom: 51px;    
    min-height: 240px;
}

#description .descLongue {
    color: #fefefe;
    font-size: 36px;    
    font-weight: lighter;
}

#description .motsClef {
    color: #9e000e;    
    font-size: 36px;    
    font-weight: lighter;
}

#description .sousTexte {
    color: #565656;
    font-size: 24px;    
    font-weight: lighter;
}

#spectacles {
    margin-top: 12px;
    margin-bottom: 75px;
    font-size: 26px;        
    font-weight: lighter;    
}

#spectacles button {
    filter: invert(100%);
}

#spectacles .papier {
    margin-bottom: 55px;
}

#spectacles .titre {
    font-size: 24px;    
    color: white;
    font-weight: bold;
}

#spectacles .subText {
    font-size: 18px;
}

#spectacles .gray {
    color: #848484;
}

#spectacles .boutonAcheter button {
    font-size: 16px;
    float: right;
    min-width: 8em;
    color: black;
    margin-top: 15px;
}

#etoiles {
    background: url("/wp-content/themes/christianvanasse/assets/images/backgrounds/fondRouge.jpg");
    padding: 70px 0px 30px;
    min-height: 90px;
 }

 
 #etoiles .etoilesNoir {
     opacity: 0.5;
     filter: invert(100%);
    }
    
    #etoiles .etoilesBlanc {
        opacity: 0.99;    
        margin-left: -350px;
        z-index: 2;
        position: absolute;
    }
    
#etoiles .boite-etoiles {
}

#etoiles .nbEtoiles-container {
    
}

#biographie {
    padding: 4%;
    padding-right: 20%;
    background: white;
    background-image: url("/wp-content/themes/christianvanasse/assets/images/misc/cravatte.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 22px;
    line-height: 2em;
}

#biographie img {
    max-width: 2em;
}

@media (max-width: 40em) {
    #biographie {
    background-image: none;
    }
}

#biographie p {
    background: rgba(255,255,255,0.65);
}

#biographie .cravatte img {
    max-height: 100%;
    width: 30%;
}
  
#footer {
    background: #2c2b2b;
    color: white;
    font-weight: bold;    
    padding: 30px;
}

.papier {
    position: relative;
}

.contenu {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    font-weight: bold;
}

#footer .papier {
    font-size: 15px;    
    color: #2c2b2b;
}

#etoiles .img-container { 
    position: relative;
    text-align: center;
    padding-bottom: 25px;
 }

#etoiles .img-container .top {
  padding-bottom: 25px;    
  position: absolute;
  z-index: 1; 
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}